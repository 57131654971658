<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.title"
        placeholder="请输入题目名称"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.student_question_bank_type_id"
        placeholder="请选择题目分类"
        clearable
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option
          v-for="(item, i) in TypeList"
          :key="i"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-select
        v-model="listQuery.type"
        placeholder="请选择题目类型"
        clearable
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option label="单选题" :value="1" />
        <el-option label="多选题" :value="2" />
        <el-option label="填空题" :value="3" />
        <el-option label="简答题" :value="4" />
        <el-option label="判断题" :value="5" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter">
        搜索
      </el-button>
      <el-button class="filter-item" type="primary" @click="handleReturn">
        返回
      </el-button>
      <el-button
        class="filter-item"
        type="success"
        @click="handleAdd"
        :loading="btnLoading"
      >
        添加选中题目
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @cell-click="cellClick"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="ID" width="95" align="center">
        <template slot-scope="scope">
          <!-- {{ scope.$index + 1 }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="题目分类" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.question_bank_type.name }}
        </template>
      </el-table-column>
      <el-table-column label="题目类型" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.type == 1
              ? "单选题"
              : scope.row.type == 2
              ? "多选题"
              : scope.row.type == 3
              ? "填空题"
              : scope.row.type == 4
              ? "简答题"
              : "判断题"
          }}
        </template>
      </el-table-column>
      <el-table-column label="题目" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column label="排序" min-width="110" align="center">
        <template slot-scope="scope">
          <el-input
            ref="gain"
            type="number"
            v-if="scope.row.isEdit_weight"
            @blur="blurClick(scope, scope.$index)"
            :autofocus="true"
            v-model="scope.row.sort_weight"
          ></el-input>
          <span v-else
            >{{ scope.row.sort_weight }}<i class="el-icon-edit"></i
          ></span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="280"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleCreate(scope.row.id)"
            >选择</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      title="编辑"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="130px">
        <el-form-item label="设置分数:" prop="score">
          <el-input type="text" v-model="form.score" clearable />
        </el-form-item>
        <el-form-item label="题目排序:" prop="sort_weight">
          <el-input type="text" v-model="form.sort_weight" clearable />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="批量添加"
      :visible.sync="dialogAllFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="130px">
        <el-form-item label="设置分数:" prop="score">
          <el-input type="text" v-model="form.score" clearable />
        </el-form-item>
        <!-- <el-form-item label="题目排序:" prop="sort_weight">
          <el-input type="text" v-model="form.sort_weight" clearable />
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogAllFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveAllData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: [],
      total: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        title: "",
        type: "",
        student_question_bank_type_id:'',
        is_on: "",
        exist_ids: [],
      },
      dialogFormVisible: false,
      dialogAllFormVisible: false,
      form: {
        index: "",
        teacher_paper_id: "",
        student_question_bank_id: "",
        score: "",
        sort_weight: "",
      },
      rules: {
        score: [{ required: true, message: "分数不能为空", trigger: "change" }],
        sort_weight: [
          { required: true, message: "排序不能为空", trigger: "change" },
        ],
      },
      btnLoading: false,
      school_list: [],
      multipleSelection: [],
      student_question_bank_ids: [],
      TypeList: [],
    };
  },
  created() {
    this.form.teacher_paper_id = this.$route.query.id;
    this.listQuery.exist_ids = this.$route.query.exist_ids;
    this.getList();
    this.getTypeList();
  },
  methods: {
    getTypeList() {
      request({
        url: "/api/teacherend/studentQuestionBank/typeList",
        method: "get",
        // params: this.listQuery,
      }).then((response) => {
        this.TypeList = response.data.data;
      });
    },
    handleAdd() {
      if (!this.multipleSelection.length) {
        this.$message.error('请选择题目')
        return
      }
      this.multipleSelection.forEach((item, index) => {
        this.student_question_bank_ids.push(item.id);
      });
      console.log(this.student_question_bank_ids);
      this.dialogAllFormVisible = true;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    saveAllData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          console.log(this.student_question_bank_ids, "添加");
          request({
            url: "/api/teacherend/teacherPaper/questionBankManyAdd",
            method: "post",
            data: {
              teacher_paper_id: this.form.teacher_paper_id,
              score: this.form.score,
              student_question_bank_ids: this.student_question_bank_ids,
            },
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogAllFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$router.go(-1); //返回上一层
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    blurClick() {
      this.list.forEach((item) => {
        item.isEdit_weight = false;
      });
    },
    cellClick(row, column, event) {
      if (column.label === "排序") {
        this.$set(row, "isEdit_weight", true);
      }
      this.$forceUpdate();
    },
    handleReturn() {
      this.$router.replace({
        path: "/question/topic?id=" + this.$route.query.id,
      });
      let visitedViews = this.$store.state.tagsView.visitedViews;
      let index = visitedViews.findIndex(
        (item) => item.path == "/question/topic_list"
      );
      this.$store.state.tagsView.visitedViews.splice(index, 1);
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/studentQuestionBank/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.list.forEach((item) => {
          item.isEdit_weight = false;
        });
        this.total = response.data.total;
        this.listLoading = false;
      });
    },

    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/teacherend/teacherPaper/questionBankStore",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.form.sort_weight = "";
              this.form.score = "";
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.listQuery.exist_ids +=
                "," + this.form.student_question_bank_id;
              this.getList();
              // this.$router.go(-1); //返回上一层
              // this.handleReturn()
              // this.$router.replace({ path: "/question/topic?id=3" });
              // let visitedViews = this.$store.state.tagsView.visitedViews;
              // let index = visitedViews.findIndex(
              //   (item) => item.path == "/question/topic_list"
              // );
              // this.$store.state.tagsView.visitedViews.splice(index, 1);
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleCreate(id) {
      this.form.student_question_bank_id = id;
      this.dialogFormVisible = true;
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-icon-edit {
  margin-left: 5px;
}
</style>
